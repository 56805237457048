import { UserType, auth, firebaseAuthProviders, useLoginResultType } from '@/configs/firebase';
import { useBluwhale } from '@/context/BluwhaleContext';
import { onLoginOptionType } from '@/types/login';
import { TwitterAuthProvider, fetchSignInMethodsForEmail, linkWithCredential, signInWithPopup } from 'firebase/auth';
import useLoginByBluwhaleAuth from './useLoginByBluwhaleAuth';
import { basicConfig } from '@/configs';

export default function useLoginByTwitter() {
  const BASIC_URL = basicConfig.auth.servicesBasicUrl;
  const { onSetUser, onUpdateUser, user, setLoading } = useBluwhale();
  const { onSignin, onLink } = useLoginByBluwhaleAuth({ BASIC_URL });

  /**
   *
   * @param error
   * @returns
   * @description
   * because of the firebase auth only allow same gmail to only login one account,
   * so we need to link the account if the user want to login with the same gmail
   */
  async function handleTwitterLoginFailure(error: any) {
    console.debug('handleTwitterLoginFailure', error);
    console.log(Object.keys(error));
    console.log('values', Object.values(error));

    const credential = TwitterAuthProvider.credentialFromError(error);

    const { email } = error.customData;
    console.log('email', email);
    let signInMethods = null;
    try {
      signInMethods = await fetchSignInMethodsForEmail(auth, email);
    } catch (e) {
      console.log('fetchSignInMethodsForEmail e', e);
    }
    if (!signInMethods) {
      throw new Error('signInMethods is null');
    }
    console.log('signInMethods ', signInMethods);

    // google linked
    const providerKey =
      signInMethods && signInMethods.length > 0
        ? (signInMethods[0].split('.')[0] as keyof typeof firebaseAuthProviders)
        : 'google';
    const _provider = firebaseAuthProviders[providerKey];

    _provider.setCustomParameters({ login_hint: email });

    const result = await signInWithPopup(auth, _provider);
    if (!credential) {
      throw new Error('credential is null');
    }
    try {
      const linkRes = await linkWithCredential(result.user, credential);
      console.log('linkRes', linkRes);
      return linkRes;
    } catch (e) {
      console.log('linkWithCredential e', e);
      throw new Error('linkWithCredential failure');
    }
  }
  /**
   *
   * @returns
   * @description
   *
   */
  async function handleSignin() {
    try {
      const provider = new TwitterAuthProvider();
      let credential = null;
      try {
        credential = await signInWithPopup(auth, provider);
      } catch (error) {
        credential = await handleTwitterLoginFailure(error);
        setLoading?.(false);

        if (!credential) {
          throw new Error('link failure');
        }
      }
      const token = await credential.user.getIdToken();
      if (!token) {
        setLoading?.(false);

        throw new Error('token is null');
      }
      return { credential, token };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      throw new Error('login failure');
    }
  }

  async function signin({ userType, isSignUp }: onLoginOptionType): Promise<useLoginResultType> {
    try {
      setLoading?.(true);
      const { credential, token } = await handleSignin();
      const bluwhaleUser = await onSignin({ token, userType, isSignUp });
      onSetUser({ credential, user: bluwhaleUser });
      return { result: bluwhaleUser, action: 'signin', isSuccess: true };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      return { result: undefined, action: 'signin', isSuccess: false, error: e };
    }
  }
  async function link({ accessToken, userType, isSignUp }: onLoginOptionType): Promise<useLoginResultType> {
    try {
      setLoading?.(true);

      const preToken = accessToken ?? (await auth.currentUser?.getIdToken());
      const preUser = auth.currentUser;
      if (!preToken || !preUser) {
        throw new Error('No preToken received');
      }
      const { credential, token } = await handleSignin();

      const bluwhaleUser = await onLink({ token, preToken, userType, isSignUp });
      setLoading?.(false);

      if (!bluwhaleUser) {
        throw new Error('No bluwhaleUser received');
      }
      onUpdateUser({ firebaseUser: preUser, token: preToken, user: bluwhaleUser });
      return { result: bluwhaleUser, action: 'link', isSuccess: true };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      return { result: undefined, action: 'link', isSuccess: false, error: e };
    }
  }
  return { onSignin: signin, onLink: link };
}
