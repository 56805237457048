import { auth, useLoginResultType } from '@/configs/firebase';
import { useBluwhale } from '@/context/BluwhaleContext';
import useLoginByBluwhaleAuth from './useLoginByBluwhaleAuth';
import { basicConfig } from '@/configs';
import { onLoginOptionType } from '@/types/login';
import { useCallback, useEffect, useRef } from 'react';
import usePopupWindow from './usePopupWindows';
import { useRouter } from 'next/router';

export default function useLoginByLinkedin() {
  const BASIC_URL = basicConfig.auth.servicesBasicUrl;
  const CLIENT_ID = basicConfig.auth.linkedinClientId;
  const { onSetUser, onUpdateUser, setLoading } = useBluwhale();
  const { onSignin, onLink, onLinkedinSignin } = useLoginByBluwhaleAuth({ BASIC_URL });
  const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

  const redirectUri = `${origin}/login/callback`;
  const url = setupLinkedinUrl(CLIENT_ID ?? '', redirectUri);

  const openPopupWindow = usePopupWindow(
    url,
    { height: 600, width: 600 },
    // , handleReceiveMessage
  );
  function setupLinkedinUrl(_client_id: string, _redirectUri = `${origin}/login/callback`) {
    const state = 'random-string-for-csrf-protection';
    const scope = 'openid%20profile%20email';
    return `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${_client_id}&redirect_uri=${_redirectUri}&scope=${scope}&state=${state}`;
  }

  /**
   *
   * @returns
   * @description
   * 1. Get linkedin credential
   * 2. Get firebase custom token
   * 3. Signin with firebase custom token
   */
  async function handleSignin(): Promise<{ credential: any; token: string }> {
    try {
      const result = await openPopupWindow();
      if (!result) {
        throw new Error('PopupWindow No result received');
      }
      const signinResult = await onLinkedinSignin({ ...result, redirect_uri: redirectUri });
      if (!signinResult) {
        throw new Error('LinkedinSignin No result received');
      }
      return signinResult;
    } catch (e) {
      setLoading?.(false);

      console.error('handleSignin failure', e);
      throw new Error('handleSignin failure');
    }
  }

  async function signin({ userType }: onLoginOptionType): Promise<useLoginResultType> {
    try {
      setLoading?.(true);
      const { token, credential } = await handleSignin();
      const bluwhaleUser = await onSignin({ token, userType });
      if (bluwhaleUser) {
        onSetUser({ credential, user: bluwhaleUser });
      }
      setLoading?.(false);

      return { result: bluwhaleUser, action: 'signin', isSuccess: true };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      return { result: undefined, action: 'signin', isSuccess: false, error: e };
    }
  }
  async function link({ accessToken, userType }: onLoginOptionType): Promise<useLoginResultType> {
    try {
      setLoading?.(true);

      const preToken = accessToken ?? (await auth.currentUser?.getIdToken());
      const preUser = auth.currentUser;
      if (!preToken || !preUser) {
        setLoading?.(false);
        throw new Error('No preToken received');
      }
      const { token, credential } = await handleSignin();

      const bluwhaleUser = await onLink({ token, preToken, userType });
      setLoading?.(false);

      if (!bluwhaleUser) {
        throw new Error('No bluwhaleUser received');
      }
      onUpdateUser({ firebaseUser: preUser, token: preToken, user: bluwhaleUser });
      return { result: bluwhaleUser, action: 'link', isSuccess: true };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      return { result: undefined, action: 'link', isSuccess: false, error: e };
    }
  }
  return { onSignin: signin, onLink: link };
}
