import { basicConfig } from '@/configs';
import { UserType, auth, useLoginResultType } from '@/configs/firebase';
import { useBluwhale } from '@/context/BluwhaleContext';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import useLoginByBluwhaleAuth from './useLoginByBluwhaleAuth';

export default function useLoginByGoogle() {
  const BASIC_URL = basicConfig.auth.servicesBasicUrl;
  const { onSetUser, onUpdateUser, setLoading } = useBluwhale();
  const { onLink, onSignin } = useLoginByBluwhaleAuth({ BASIC_URL });

  async function handleGoogleSigninFailure(error: any) {
    console.error('handleGoogleSigninFailure', error);
    return undefined;
  }
  async function handleSignin() {
    try {
      const provider = new GoogleAuthProvider();
      let credential = null;
      try {
        credential = await signInWithPopup(auth, provider);
      } catch (error) {
        credential = await handleGoogleSigninFailure(error);
        if (!credential) {
          throw new Error('link failure');
        }
      }
      const token = await credential.user.getIdToken();
      if (!token) {
        throw new Error('token is null');
      }
      return { credential, token };
    } catch (e) {
      console.log('e', e);
      throw new Error('login failure');
    }
  }
  async function signin({
    userType,
    isSignUp,
  }: {
    userType: UserType;
    isSignUp: boolean;
  }): Promise<useLoginResultType | any> {
    try {
      setLoading?.(true);
      const { credential, token } = await handleSignin();
      const bluwhaleUser = await onSignin({ token, userType, isSignUp });
      if (bluwhaleUser) {
        onSetUser({ credential, user: bluwhaleUser });
      }
      return { result: bluwhaleUser, action: 'signin', isSuccess: true };
    } catch (e: any) {
      console.error('google signin', e);
      setLoading?.(false);
      return { result: undefined, action: 'signin', isSuccess: false, error: e, errorCode: e?.response?.status };
    }
  }

  async function link({
    userType,
    accessToken,
    // setLoading,
    isSignUp,
  }: {
    userType: UserType;
    accessToken?: string;
    // setLoading?: (loading: boolean) => void;
    isSignUp: boolean;
  }): Promise<useLoginResultType | any> {
    try {
      setLoading?.(true);

      const preToken = accessToken ?? (await auth.currentUser?.getIdToken());
      const preUser = auth.currentUser;
      if (!preToken || !preUser) {
        setLoading?.(false);
        throw new Error('No preToken received');
      }
      const { credential, token } = await handleSignin();

      const bluwhaleUser = await onLink({ token, preToken, userType, isSignUp });
      if (!bluwhaleUser) {
        setLoading?.(false);
        throw new Error('No bluwhaleUser received');
      }

      onUpdateUser({ firebaseUser: preUser, token: preToken, user: bluwhaleUser });
      setLoading?.(false);
      return { result: bluwhaleUser, action: 'link', isSuccess: true };
    } catch (e) {
      console.error('google link', e);
      return { result: undefined, action: 'link', isSuccess: false, error: e };
    }
  }

  return { onSignin: signin, onLink: link };
}
