import { UserType, auth } from '@/configs/firebase';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { signInWithCustomToken } from 'firebase/auth';

export type useLoginByBluwhaleBasicType = {
  token: string;
  preToken: string;
};
export type onWeb3ReqeustMessageOptions = {
  address: string;
  chainId: string;
  type: string;
};
export type onWeb3SigninOptions = {
  message: string;
  signature: string;
};
export type onLinkedinSigninOptions = {
  code: string;
  redirect_uri: string;
};
export type onTelegramSigninOptions = {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  auth_date: number;
  hash: string;
};
export type onPrivySigninOptions = {
  token: string;
};
export type onSigninOptions = {
  token: string;
  userType: UserType;
  isSignUp?: boolean;
  referralCode?: string;
};
export type onLinkOptions = onSigninOptions & {
  preToken: string;
};

export type BluwhaleUser = {
  uuid: string;
};

export default function useLoginByBluwhaleAuth({ BASIC_URL }: { BASIC_URL: string }) {
  const toast = useToast();

  async function onFirebaseWeb3ReqeustMessage({ address, chainId, type }: onWeb3ReqeustMessageOptions) {
    const url = `${BASIC_URL}/web3/request-message/`;
    const body = JSON.stringify({
      address,
      chain_id: 1,
      type,
    });
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post(url, body, options);
    const json = await res.data;
    console.log(json);
    const { message } = json;
    return message;
  }
  async function onFirebaseWeb3Signin({
    message,
    signature,
  }: onWeb3SigninOptions): Promise<{ credential: any; token: string } | undefined> {
    const url = `${BASIC_URL}/web3/sign-in/`;
    const body = JSON.stringify({
      message,
      signature,
    });
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(url, body, options);
      const result = await res.data;
      const credential = await signInWithCustomToken(auth, result.token);
      const token = await credential.user.getIdToken(true);
      return { credential, token };
    } catch (e) {
      console.error('axiosFirebaseSignin', e);
      return undefined;
    }
  }
  async function onTelegramSignin(props: onTelegramSigninOptions) {
    const url = `${BASIC_URL}/telegram/sign-in/`;
    const body = JSON.stringify(props);
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(url, body, options);
      const result = await res.data;
      const credential = await signInWithCustomToken(auth, result.token);
      const token = await credential.user.getIdToken(true);
      return { credential, token };
    } catch (e) {
      console.error('onTelegramSignin', e);
      return undefined;
    }
  }
  async function onLinkedinSignin({ code, redirect_uri }: onLinkedinSigninOptions) {
    const url = `${BASIC_URL}/linkedin/sign-in/`;
    const body = JSON.stringify({ code, redirect_uri });
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(url, body, options);
      const result = await res.data;
      console.log('onLinkedinSignin result', result);
      const credential = await signInWithCustomToken(auth, result.token);
      const token = await credential.user.getIdToken(true);
      return { credential, token };
    } catch (e) {
      console.error('onLinkedinSignin', e);
      return undefined;
    }
  }
  async function onPrivySignin({ token: _token }: onPrivySigninOptions) {
    const url = `${BASIC_URL}/privy/sign-in/`;
    const body = JSON.stringify({ token: _token });
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(url, body, options);
      const result = await res.data;
      const credential = await signInWithCustomToken(auth, result.token);
      const token = await credential.user.getIdToken(true);
      return { credential, token };
    } catch (e) {
      console.error('onPrivySignin', e);
      return undefined;
    }
  }
  async function onSignin({ token, userType, isSignUp, referralCode }: onSigninOptions) {
    const url = `${BASIC_URL}/sign-in/`;
    const body = JSON.stringify({
      token,
      user_type: userType,
      auto_signup: isSignUp,
      referral_code: referralCode,
      // ...(referralCode && { referral_code: referralCode ?? '' }),
    });
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(url, body, options);
      const result = await res.data;
      console.log('bluwhale signin result', result);
      return result;
    } catch (error: any) {
      if (error?.response?.status === 403) {
        toast({
          description: error?.response?.data?.detail,
          // 'The chosen social account is linked to another profile. Kindly opt for a different identity for your registration',
          status: 'error',
          position: 'top',
        });
        return undefined;
      }
      if (error?.response?.status === 400) {
        toast({
          description: 'User does not exist',
          status: 'error',
          position: 'top',
        });
        return undefined;
      }
      return undefined;
    }
  }
  async function onLink({ token, preToken, userType }: onLinkOptions) {
    const url = `${BASIC_URL}/link/`;
    const body = JSON.stringify({
      token,
      pre_token: preToken,
      user_type: userType,
    });
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res2 = await axios.post(url, body, options);

      const result = await res2.data;
      return result;
    } catch (error: any) {
      if (error?.response?.status === 403) {
        toast({
          description:
            'The chosen social account is linked to another profile. Kindly opt for a different identity for your registration',
          status: 'error',
          position: 'top',
        });
      }
      return undefined;
    }
  }
  return {
    onFirebaseWeb3ReqeustMessage,
    onFirebaseWeb3Signin,
    onSignin,
    onLink,
    onLinkedinSignin,
    onTelegramSignin,
    onPrivySignin,
  };
}
