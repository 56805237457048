import { useCallback, useRef } from 'react';

interface Options {
  height: number;
  width: number;
}
const usePopupWindow = (url: string, { height, width }: Options, key = 'linkedin-login') => {
  const TIME_OUT = 30000;
  const popup = useRef<Window | null>(null);

  const openPopupWindow = useCallback(() => {
    return new Promise<{ code: string } | undefined>((resolve, reject) => {
      popup.current = window.open(url, '', `height=${height},width=${width}`);

      const handleMessage = (event: MessageEvent) => {
        if (event.origin !== window.location.origin) {
          return;
        }
        if (event.data.key !== key) {
          return;
        }

        resolve(event.data.value);
        window.removeEventListener('message', handleMessage);
      };

      window.addEventListener('message', handleMessage);

      const timer = setInterval(() => {
        if (popup.current === null) {
          clearInterval(timer);
          window.removeEventListener('message', handleMessage);
          reject(new Error('Popup has been closed'));
        }
      }, 500);
      const timeout = setTimeout(() => {
        if (popup.current) {
          popup.current.close();
        }
        resolve(undefined);
        window.removeEventListener('message', handleMessage);
        clearInterval(timer);
      }, TIME_OUT);
    });
  }, [url, height, width]);

  return openPopupWindow;
};
export default usePopupWindow;
