import { basicConfig } from '@/configs';
import { socialLoginProviders } from '@/configs/firebase';
import { useBluwhale } from '@/context/BluwhaleContext';
import useLoginByGoogle from '@/hooks/useLoginByGoogle';
import useLoginByLinkedin from '@/hooks/useLoginByLinkedin';
import useLoginByTelegram from '@/hooks/useLoginByTelegram';
import useLoginByTwitter from '@/hooks/useLoginByTwitter';
import { Flex, Button, Box, Text, Image } from '@chakra-ui/react';
import React, { useEffect } from 'react';

function LinkTest() {
  const { telegrambotName, telegrambotId, telegramRedirectUrl } = basicConfig.auth;

  const { onLink: onLinkLinkedin } = useLoginByLinkedin();
  const { onLink: onLinkGoogle } = useLoginByGoogle();
  const { onLink: onLinkTwitter } = useLoginByTwitter();
  const { onLink: onLinkTelegram } = useLoginByTelegram({
    botName: telegrambotName ?? '',
    botId: telegrambotId ?? '',
    redicrectUrl: typeof window !== 'undefined' ? `${window.location.hostname}/${telegramRedirectUrl}` : '',
  });
  const { firebaseUser, user } = useBluwhale();
  const accessToken = firebaseUser?.accessToken;

  useEffect(() => {
    console.log('bluwhale user', user);
  }, [user]);

  async function handleLinkLinkedin() {
    const result = await onLinkLinkedin({ accessToken, userType: 'consumer' });
    console.log('handleLinkLinkedin', result);
  }
  async function handleLinkGoogle() {
    const result = await onLinkGoogle({ accessToken, userType: 'consumer', isSignUp: false });
    console.log('handleLinkGoogle', result);
  }
  async function handleLinkTwitter() {
    const result = await onLinkTwitter({ accessToken, userType: 'consumer', isSignUp: false });
    console.log('handleLinkTwitter', result);
  }
  async function handleLinkTelegram() {
    const result = await onLinkTwitter({ accessToken, userType: 'consumer', isSignUp: false });
    console.log('handleLinkTwitter', result);
  }

  return (
    <Flex flexDir={'column'} w={'100%'} gap={4}>
      <Flex justifyItems={'space-between'} alignItems={'center'} w={'100%'}>
        <Box flex={1} gap={2}>
          <Image
            display={'inline-block'}
            src={'/images/icon/profile-pop/google.png'}
            rounded={'full'}
            w={10}
            h={10}
            mr={4}
          />
          <Text display={'inline-block'}>Google</Text>
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          {user?.social_links?.google ? (
            <Text color="#7C7C7C" textAlign={'center'} h={8} px={1}>
              Connected
            </Text>
          ) : (
            <Button
              onClick={handleLinkGoogle}
              colorScheme="purple"
              bg="#6235D0"
              rounded="full"
              color="#fff"
              fontWeight="bold"
              px={4}
              h={8}
              alignSelf={'center'}
              cursor={'pointer'}
            >
              Connect
            </Button>
          )}
        </Box>
      </Flex>
      <Flex justifyItems={'space-between'} alignItems={'center'}>
        <Box flex={1} gap={2}>
          <Image
            display={'inline-block'}
            src={'/images/icon/profile-pop/twitter.png'}
            rounded={'full'}
            w={10}
            h={10}
            mr={4}
          />
          <Text display={'inline-block'}>Twitter</Text>
        </Box>

        {user?.social_links?.twitter ? (
          <Text color="#7C7C7C">Connected</Text>
        ) : (
          <Button
            onClick={handleLinkTwitter}
            colorScheme="purple"
            bg="#6235D0"
            rounded="full"
            color="#fff"
            fontWeight="bold"
            px={4}
            h={8}
            alignSelf={'center'}
            cursor={'pointer'}
          >
            Connect
          </Button>
        )}
      </Flex>
      <Flex justifyItems={'space-between'} alignItems={'center'}>
        <Box flex={1} gap={2}>
          <Image
            display={'inline-block'}
            src={'/images/icon/profile-pop/telegram.png'}
            rounded={'full'}
            w={10}
            h={10}
            mr={4}
          />
          <Text display={'inline-block'}>Telegram</Text>
        </Box>

        {user?.social_links?.telegram ? (
          <Text color="#7C7C7C">Connected</Text>
        ) : (
          <Button
            onClick={handleLinkTelegram}
            colorScheme="purple"
            bg="#6235D0"
            rounded="full"
            color="#fff"
            fontWeight="bold"
            px={4}
            h={8}
            alignSelf={'center'}
            cursor={'pointer'}
          >
            Connect
          </Button>
        )}
      </Flex>
      <Flex justifyItems={'space-between'} alignItems={'center'}>
        <Box flex={1} gap={2}>
          <Image
            display={'inline-block'}
            src={'/images/icon/profile-pop/linkedin.png'}
            rounded={'full'}
            w={10}
            h={10}
            mr={4}
          />
          <Text display={'inline-block'}>LinkedIn</Text>
        </Box>

        {user?.social_links?.linkedin ? (
          <Text color="#7C7C7C">Connected</Text>
        ) : (
          <Button
            onClick={handleLinkLinkedin}
            colorScheme="purple"
            bg="#6235D0"
            rounded="full"
            color="#fff"
            fontWeight="bold"
            px={4}
            h={8}
            alignSelf={'center'}
            cursor={'pointer'}
          >
            Connect
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default LinkTest;
