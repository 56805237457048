import { auth, firebaseAuthProviders, useLoginResultType } from '@/configs/firebase';
import { useBluwhale } from '@/context/BluwhaleContext';
import { onLoginOptionType } from '@/types/login';
import { TwitterAuthProvider, fetchSignInMethodsForEmail, linkWithCredential, signInWithPopup } from 'firebase/auth';
import useLoginByBluwhaleAuth, { onTelegramSigninOptions } from './useLoginByBluwhaleAuth';
import { basicConfig } from '@/configs';
import { useEffect, useRef } from 'react';

export type useLoginByTelegramOptions = {
  botName: string;
  botId: string;
  redicrectUrl: string;
};

declare global {
  interface Window {
    onTelegramAuth?: (user: any) => void;
    Telegram: any;
  }
}

export default function useLoginByTelegram({ botName, botId, redicrectUrl }: useLoginByTelegramOptions) {
  const BASIC_URL = basicConfig.auth.servicesBasicUrl;
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { onSetUser, onUpdateUser, user, setLoading } = useBluwhale();
  const { onSignin, onLink, onTelegramSignin } = useLoginByBluwhaleAuth({ BASIC_URL });

  useEffect(() => {
    // window.onTelegramAuth = (user: any) => {
    //   console.log('onTelegramAuth has been called');
    //   console.log(user);
    //   localStorage.setItem('telegramUser', JSON.stringify(user) ?? 'ww');
    // };
    const script = initElement(botName);
    return () => {
      if (containerRef.current) {
        containerRef.current.removeChild(script);
      }
    };
  }, []);

  const telegramAuth = (_botId: string) =>
    new Promise<onTelegramSigninOptions | undefined>((resolve, reject) => {
      window.Telegram.Login.auth({ bot_id: _botId, request_access: true, origin: redicrectUrl }, (data: any) => {
        if (!data) {
          // console.log('Authorization failed');
        } else {
          // console.log(data);
          resolve(data);
        }
      });
    });

  function initElement(_botName: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?7';
    script.async = true;

    script.setAttribute('data-telegram-login', _botName);
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-userpic', 'false');
    // script.setAttribute('data-size', 'large');
    // script.setAttribute('data-radius', '10');
    script.setAttribute('data-request-access', 'write');

    if (containerRef.current) {
      containerRef.current.appendChild(script);
      containerRef.current.style.display = 'none';
    }
    return script;
  }
  function initTelegramUrl({ _botId, _url }: { _botId: string; _url: string }): string {
    // Redirect to Telegram OAuth URL
    const redirectUrl = encodeURIComponent(_url);
    const url = `https://oauth.telegram.org/auth?bot_id=${_botId}&origin=${redirectUrl}`;
    return url;
  }
  /**
   *
   * @param error
   * @returns
   * @description
   * because of the firebase auth only allow same gmail to only login one account,
   * so we need to link the account if the user want to login with the same gmail
   */
  async function handleTwitterLoginFailure(error: any) {
    console.debug('handleTwitterLoginFailure', error);
    console.log(Object.keys(error));
    console.log('values', Object.values(error));

    const credential = TwitterAuthProvider.credentialFromError(error);

    const { email } = error.customData;
    console.log('email', email);
    let signInMethods = null;
    try {
      signInMethods = await fetchSignInMethodsForEmail(auth, email);
    } catch (e) {
      console.log('fetchSignInMethodsForEmail e', e);
    }
    if (!signInMethods) {
      throw new Error('signInMethods is null');
    }
    console.log('signInMethods ', signInMethods);

    // google linked
    const providerKey =
      signInMethods && signInMethods.length > 0
        ? (signInMethods[0].split('.')[0] as keyof typeof firebaseAuthProviders)
        : 'google';
    const _provider = firebaseAuthProviders[providerKey];

    _provider.setCustomParameters({ login_hint: email });

    const result = await signInWithPopup(auth, _provider);
    if (!credential) {
      throw new Error('credential is null');
    }
    try {
      const linkRes = await linkWithCredential(result.user, credential);
      console.log('linkRes', linkRes);
      return linkRes;
    } catch (e) {
      console.log('linkWithCredential e', e);
      throw new Error('linkWithCredential failure');
    }
  }
  /**
   *
   * @returns
   * @description
   *
   */
  async function handleSignin() {
    try {
      const result = await telegramAuth(botId);
      if (!result) {
        throw new Error('PopupWindow No result received');
      }
      const signinResult = await onTelegramSignin(result);
      if (!signinResult) {
        throw new Error('LinkedinSignin No result received');
      }
      return signinResult;
    } catch (e) {
      console.log('e', e);
      throw new Error('login failure');
    }
  }

  async function signin({ userType }: onLoginOptionType): Promise<useLoginResultType> {
    try {
      setLoading?.(true);

      const { credential, token } = await handleSignin();
      const bluwhaleUser = await onSignin({ token, userType });
      onSetUser({ credential, user: bluwhaleUser });
      setLoading?.(false);

      return { result: bluwhaleUser, action: 'signin', isSuccess: true };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      return { result: undefined, action: 'signin', isSuccess: false, error: e };
    }
  }
  async function link({ accessToken, userType }: onLoginOptionType): Promise<useLoginResultType> {
    try {
      setLoading?.(true);

      const preToken = accessToken ?? (await auth.currentUser?.getIdToken());
      const preUser = auth.currentUser;
      if (!preToken || !preUser) {
        setLoading?.(false);

        throw new Error('No preToken received');
      }
      const { credential, token } = await handleSignin();

      const bluwhaleUser = await onLink({ token, preToken, userType });
      setLoading?.(false);

      if (!bluwhaleUser) {
        throw new Error('No bluwhaleUser received');
      }
      onUpdateUser({ firebaseUser: preUser, token: preToken, user: bluwhaleUser });

      return { result: bluwhaleUser, action: 'link', isSuccess: true };
    } catch (e) {
      setLoading?.(false);

      console.log('e', e);
      return { result: undefined, action: 'link', isSuccess: false, error: e };
    }
  }
  return { onSignin: signin, onLink: link, containerRef };
}
